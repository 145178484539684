import type { DialogData } from 'actions';
import { CreateNewStationFromRightClickDialog } from 'components/editor/create-new-station-from-right-click-dialog';
import RackEditionDialog from 'components/editor/rack-edition/rack-edition';
import { RobotDataDialog } from 'components/editor/robot-data';
import SearchShapeDialog from 'components/editor/search-shape';
import StockZoneSettingsDialog from 'components/editor/stock-zone-settings/stock-zone-settings';
import LayersDialog from 'components/layers/layers-settings';
import { RotateCircuitPropertiesComponent } from 'components/properties/rotate-circuit-properties';
import { SearchShapeResultSelectionComponent } from 'components/properties/search-shape-result-selection';
import { TranslateCircuitPropertiesComponent } from 'components/properties/translate-circuit-properties';
import { ExitRunningSimulation } from 'components/toolboxes/simulation/exit-running-simulation-dialog';
import { SimulationReportDialog } from 'components/toolboxes/simulation/report';
import { UpdateIntervalThroughputDialog } from 'components/toolboxes/simulation/update-interval-throughput-dialog';
import { UpdateOPCThresholdsDialog } from 'components/toolboxes/simulation/update-opc-thresholds-dialog';
import { UpdateSchedulerConfigDialog } from 'components/toolboxes/simulation/update-scheduler-config-dialog';
import { UpdateBatteryDialog } from 'components/toolboxes/simulation/updateBatteryDialog';
import { DialogTypes } from 'models';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import SettingsDialog from '../../settings/settings';
import { AccessRequiredDialog } from '../access-required';
import { CreateProjectDialog } from '../create-project-dialog';
import { ProjectSettingsDialog } from '../project-settings/project-settings-dialog';
import RackAnalysisDialog from '../rack-analysis/rack-analysis-dialog';
import ShareDialog from '../share-dialog';
import WelcomeDialog from '../welcome-dialog';
import { DialogImageScaling } from './dialog-image-scaling/dialog-image-scaling.container';

export interface DialogDisplayerProps {
  closeDialog: () => void;
  open: boolean;
  type: DialogTypes;
  data: DialogData;
}

export function DialogDisplayer({
  open,
  type: dialogType,
  closeDialog,
  data,
}: DialogDisplayerProps): JSX.Element | null {
  if (!open || !dialogType || dialogType === DialogTypes.None) {
    return null;
  }

  switch (dialogType) {
    case DialogTypes.Welcome: {
      return <WelcomeDialog />;
    }

    case DialogTypes.Share: {
      return <ShareDialog />;
    }

    case DialogTypes.ImageScaling: {
      return (
        <DialogImageScaling
          closeDialog={closeDialog}
          initialScaling={data && 'distance' in data && 'points' in data ? data : undefined}
        />
      );
    }

    case DialogTypes.Settings: {
      return <SettingsDialog />;
    }

    case DialogTypes.Layers: {
      return <LayersDialog />;
    }

    case DialogTypes.SearchShape: {
      return <SearchShapeDialog />;
    }

    case DialogTypes.UpdateBattery: {
      if (!data || !('robotsList' in data)) {
        throw new Error('RobotsList data is required for update robot battery');
      }

      return <UpdateBatteryDialog robotsList={data.robotsList} />;
    }

    case DialogTypes.UpdateOPCThresholds: {
      if (!data || !('robotsList' in data)) {
        throw new Error('RobotsList data is required for update OPC');
      }

      return <UpdateOPCThresholdsDialog robotsList={data.robotsList} />;
    }

    case DialogTypes.UpdateSchedulerConfig: {
      return <UpdateSchedulerConfigDialog />;
    }

    case DialogTypes.CreateNewStationFromRightClick: {
      if (!data || !('stations' in data)) {
        throw new Error('Stations data is required to create a new station');
      }

      return <CreateNewStationFromRightClickDialog stations={data.stations} selectedShapes={data.selectedShapes} />;
    }

    case DialogTypes.StockZoneSettings: {
      return <StockZoneSettingsDialog />;
    }

    case DialogTypes.RackEdition: {
      if (!data || !('zoomToCellId' in data)) {
        return <RackEditionDialog />;
      }

      return <RackEditionDialog zoomToCellId={data.zoomToCellId} />;
    }

    case DialogTypes.RobotData: {
      if (!data || !('robotName' in data)) {
        throw new Error('Robot data is required for robot data dialog');
      }

      return <RobotDataDialog robotName={data.robotName} />;
    }

    case DialogTypes.AccessRequired: {
      return <AccessRequiredDialog />;
    }

    case DialogTypes.ProjectSettings: {
      return <ProjectSettingsDialog />;
    }

    case DialogTypes.TranslateCircuit: {
      return <TranslateCircuitPropertiesComponent />;
    }

    case DialogTypes.RotateCircuit: {
      return <RotateCircuitPropertiesComponent />;
    }

    case DialogTypes.SearchShapeResultSelection: {
      return <SearchShapeResultSelectionComponent />;
    }

    case DialogTypes.SimulationReport: {
      return <SimulationReportDialog />;
    }

    case DialogTypes.CreateProject: {
      return <CreateProjectDialog />;
    }

    case DialogTypes.RackAnalysis: {
      return <RackAnalysisDialog />;
    }

    case DialogTypes.ExitRunningSimulation: {
      if (!data || !('newToolOrMode' in data)) {
        throw new Error('Tool or mode name is required for exit running simulation dialog');
      }

      return <ExitRunningSimulation newToolOrMode={data.newToolOrMode} />;
    }

    case DialogTypes.UpdateIntervalThroughput: {
      return <UpdateIntervalThroughputDialog />;
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unknown dialog type: ${dialogType}`);

      assert<Equals<typeof dialogType, never>>();

      return null;
    }
  }
}
