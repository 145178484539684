import { useDebouncedValue } from '@mantine/hooks';
import { Queue, Shuffle, ShuffleOn } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import type { SelectChangeEvent, Theme } from '@mui/material';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import type { SxProps } from '@mui/system';
import { Box, Stack } from '@mui/system';
import { TOOL_LIST_ALL_TOOLS } from 'components/menu-bar/tool-info';
import { Border } from 'components/utils/border';
import { collapseTransitionDuration } from 'components/utils/constants';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Station, StationPosition, StationPositionWithName } from 'flows/flows';
import {
  addPositionToStation,
  addSeveralPositionsToStation,
  addStation,
  removePositionFromStation,
  removeStation,
  setPositionInLine,
  setSelectedStationId,
  setStationName,
  stationPositionTypesIcons,
  togglePositionInLineRandom,
} from 'flows/flows';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { capitalize } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { ShapeTypes, SlotTypes } from 'models/circuit';
import { Tools } from 'models/tools';
import { startTransition, useCallback, useMemo, useRef, useState } from 'react';
import { ViewportList } from 'react-viewport-list';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch, useAppSelector } from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { getAllPositions } from 'utils/circuit/get-all-positions';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { theme } from 'utils/mui-theme';
import type { StationPositionType } from '../../flows/flows';

export function StationsToolbox(): JSX.Element {
  const stations = useAppSelector((state) => state.flows.stations);
  const selectedStationId = useAppSelector((state) => state.flows.selectedStationId);
  const [delayedSelectedStationId] = useDebouncedValue(selectedStationId, collapseTransitionDuration);

  const [editName, setEditName] = useState(false);

  const selectedStation = useMemo(() => {
    return stations.find((station) => station.id === selectedStationId);
  }, [selectedStationId, stations]);
  const delayedSelectedStation = useMemo(() => {
    return stations.find((station) => station.id === delayedSelectedStationId);
  }, [delayedSelectedStationId, stations]);

  const AvatarIcon = useMemo(() => {
    return TOOL_LIST_ALL_TOOLS.find((tool) => tool.tool === Tools.StationsConfiguration)?.icon;
  }, []);

  const handleSetEditName = useCallback((newValue: boolean) => {
    setEditName(newValue);
  }, []);

  const displayStationData = selectedStationId === delayedSelectedStationId;

  return (
    <Card
      sx={{
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        width: '400px',
        overflowY: 'auto',
        maxHeight: '95%',
      }}
    >
      <CardHeader
        title="Stations Configuration"
        avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
      ></CardHeader>

      <CardContent
        sx={{
          textAlign: 'left',
        }}
      >
        <Stack direction="row" spacing={1}>
          {!editName ? (
            <StationListAndSelection
              selectedStationId={selectedStationId}
              stations={stations}
              handleSetEditName={handleSetEditName}
            />
          ) : (
            selectedStation && (
              <RenameStation station={selectedStation} stations={stations} handleSetEditName={handleSetEditName} />
            )
          )}
        </Stack>

        {selectedStation && (
          <Collapse
            in={displayStationData}
            timeout={delayedSelectedStation ? collapseTransitionDuration : 0}
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            <Border>
              <AddPositionToStation
                station={!displayStationData && delayedSelectedStation ? delayedSelectedStation : selectedStation}
              />
              <ListPositionOfStation
                station={!displayStationData && delayedSelectedStation ? delayedSelectedStation : selectedStation}
              />
            </Border>
          </Collapse>
        )}
      </CardContent>
    </Card>
  );
}

interface StationListAndSelectionProps {
  selectedStationId?: string;
  stations: Station[];
  handleSetEditName?: (newValue: boolean) => void;
}
export function StationListAndSelection(props: StationListAndSelectionProps): JSX.Element {
  const { selectedStationId, stations, handleSetEditName } = props;

  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const selectedStation = useMemo(() => {
    return stations.find((station) => station.id === selectedStationId);
  }, [selectedStationId, stations]);

  const handleSelectStationId = useCallback(
    (e: SelectChangeEvent<string>) => {
      dispatch(setSelectedStationId(e.target.value));
    },
    [dispatch]
  );

  const editName = useCallback(() => {
    if (handleSetEditName) handleSetEditName(true);
  }, [handleSetEditName]);

  const handleDeleteStation = useCallback(async () => {
    if (!selectedStation) {
      // eslint-disable-next-line no-console
      console.error('No station selected');

      return;
    }

    try {
      await confirm({ title: `Are you sure you want to remove "${selectedStation.name}"?`, allowClose: false });
    } catch (e) {
      return;
    }

    dispatch(removeStation(selectedStation.id));

    SnackbarUtils.success(`Station "${selectedStation.name}" removed`);
  }, [confirm, dispatch, selectedStation]);

  const handleCreateStation = useCallback(() => {
    let newStationName = 'New Station';
    let nb = 1;
    // eslint-disable-next-line no-loop-func
    while (stations.find((station) => station.name === newStationName)) {
      newStationName = `New Station ${++nb}`;
    }

    const newStationId = generateShapeId();

    dispatch(
      addStation({
        name: newStationName,
        id: newStationId,
        positions: [],
      })
    );

    dispatch(setSelectedStationId(newStationId));

    SnackbarUtils.success(`Station "${newStationName}" created`);

    editName();
  }, [dispatch, editName, stations]);

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="stations-list">Stations</InputLabel>
        <Select
          labelId="stations-list"
          label="Stations"
          id="stations-list"
          value={selectedStationId || ''}
          onChange={handleSelectStationId}
          size="small"
        >
          {stations.map((station) => (
            <MenuItem key={station.id} value={station.id}>
              {station.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Tooltip title="Rename this station">
        <Box component={'span'}>
          <IconButton aria-label="rename" disabled={!selectedStationId} onClick={editName}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="Delete this station">
        <Box component={'span'}>
          <IconButton aria-label="delete" disabled={!selectedStationId} onClick={handleDeleteStation}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="Create a station">
        <Box component={'span'}>
          <IconButton aria-label="add" onClick={handleCreateStation}>
            <AddCircleIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}

interface RenameStationProps {
  station: Station;
  stations: Station[];
  handleSetEditName?: (newValue: boolean) => void;
}
export function RenameStation(props: RenameStationProps): JSX.Element {
  const { station, stations, handleSetEditName } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);

  const handleValidateName = useCallback(() => {
    const newName = inputRef.current?.value;

    const existingStation = stations.find((st) => st.name === newName && station.id !== st.id);
    if (!newName || existingStation) {
      setError(true);

      return;
    }

    dispatch(
      setStationName({
        id: station.id,
        name: newName,
      })
    );

    // we put back the original list
    if (handleSetEditName) handleSetEditName(false);
  }, [dispatch, handleSetEditName, station.id, stations]);

  const handleRevertName = useCallback(() => {
    if (handleSetEditName) handleSetEditName(false);
  }, [handleSetEditName]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (error) setError(false);

      if (e.key === 'Enter') {
        handleValidateName();
      } else if (e.key === 'Escape') {
        handleRevertName();
      }
    },
    [error, handleRevertName, handleValidateName]
  );

  return (
    <>
      <FormControl fullWidth size="small">
        <TextField
          id="station-name"
          label="Station name"
          variant="outlined"
          size="small"
          defaultValue={station.name}
          inputRef={inputRef}
          onKeyDown={handleKeyPress}
          error={error}
          helperText={error ? 'This name is incorrect or already used' : undefined}
          autoFocus
        />
      </FormControl>

      <Tooltip title="Revert">
        <Box component={'span'}>
          <IconButton aria-label="revert" onClick={handleRevertName}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="Validate">
        <Box component={'span'}>
          <IconButton aria-label="validate" onClick={handleValidateName}>
            <CheckIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}

interface ListPositionOfStationProps {
  station: Station;
  sx?: SxProps<Theme>;
  displayRemovePositionBtn?: boolean;
  displayStockLineShuffleMode?: boolean;
}
export function ListPositionOfStation(props: ListPositionOfStationProps): JSX.Element {
  const { station, displayRemovePositionBtn = true, displayStockLineShuffleMode = true } = props;
  const dispatch = useAppDispatch();

  const handleRemovePosition = useCallback(
    (positionId: string) => {
      dispatch(
        removePositionFromStation({
          positionId,
          stationId: station.id,
        })
      );
    },
    [dispatch, station.id]
  );

  const listRef = useRef<HTMLUListElement>(null);

  const handleChangeRandomPositionStation = useCallback(
    (positionId: string) => {
      dispatch(togglePositionInLineRandom({ positionId, stationId: station.id }));
    },
    [dispatch, station.id]
  );

  const handlePropragateRandomPositionStation = useCallback(
    (newPositionInLine: (typeof station.positions)[0]['positionInLine']) => {
      const positionsIds = station.positions
        .filter((position) => {
          return position.type === SlotTypes.StockLine || position.type === ShapeTypes.StockZoneShape;
        })
        .map((position) => position.id);

      dispatch(setPositionInLine({ stationId: station.id, positionsIds, positionInLine: newPositionInLine }));
    },
    [dispatch, station]
  );

  return (
    <List
      dense={true}
      sx={{
        maxHeight: '50vh',
        overflowY: 'auto',
        ...props.sx,
      }}
      ref={listRef}
    >
      <ViewportList viewportRef={listRef} items={station.positions}>
        {(position) => {
          const Icon = stationPositionTypesIcons[position.type];
          const positionName = positionIdToPositionName(position.id, position.type);

          const isStockLineOrStockZone =
            position.type === SlotTypes.StockLine || position.type === ShapeTypes.StockZoneShape;
          const isShuffleEnabled = isStockLineOrStockZone && position.positionInLine === 'random';

          return (
            <ListItem
              key={position.id}
              secondaryAction={
                <>
                  {displayStockLineShuffleMode && isStockLineOrStockZone && (
                    <Tooltip
                      arrow
                      title={
                        <Box component="div">
                          Go to random position in line: {isShuffleEnabled ? 'enabled' : 'disabled'}
                          <br />
                          <Button
                            variant="text"
                            onClick={() =>
                              handlePropragateRandomPositionStation(isShuffleEnabled ? 'random' : undefined)
                            }
                            sx={{
                              textTransform: 'none',
                              color: 'inherit',
                            }}
                          >
                            {isShuffleEnabled ? 'Enable' : 'Disable'} for all
                          </Button>
                        </Box>
                      }
                    >
                      <IconButton
                        className="display-parent-hover"
                        edge="end"
                        aria-label="delete"
                        sx={{
                          opacity: '5%',
                          marginRight: theme.spacing(0.5),
                        }}
                        onClick={() => handleChangeRandomPositionStation(position.id)}
                      >
                        {isShuffleEnabled ? <ShuffleOn /> : <Shuffle />}
                      </IconButton>
                    </Tooltip>
                  )}
                  {displayRemovePositionBtn ? (
                    <Tooltip title={`Remove "${positionName}" from the station`} placement="left">
                      <IconButton
                        className="display-parent-hover"
                        edge="end"
                        aria-label="delete"
                        sx={{
                          opacity: '5%',
                        }}
                        onClick={() => handleRemovePosition(position.id)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Tooltip>
                  ) : undefined}
                </>
              }
              sx={{
                '&:hover .display-parent-hover': {
                  opacity: '100%',
                },
              }}
            >
              <Tooltip title={capitalize(position.type)}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={positionName} />
            </ListItem>
          );
        }}
      </ViewportList>
    </List>
  );
}

export type StationPositionTypeAndAll = StationPositionType | 'all' | 'conveyors';

const availablePositionTypes: StationPositionTypeAndAll[] = [
  'all',
  ShapeTypes.PointShape,
  ShapeTypes.RackShape,
  'conveyors',
  ShapeTypes.StockZoneShape,
  SlotTypes.Slot,
  SlotTypes.StockLine,
];

/** just a type guard */
function isStationPositionTypeAndAll(value: unknown): value is StationPositionTypeAndAll {
  return availablePositionTypes.includes(value as StationPositionTypeAndAll);
}

interface AddPositionToStationProps {
  station: Station;
}
function AddPositionToStation(props: AddPositionToStationProps): JSX.Element {
  const { station } = props;
  const dispatch = useAppDispatch();

  const stationContainsPoint = useMemo(
    () => station.positions.some((position) => position.type === ShapeTypes.PointShape),
    [station.positions]
  );
  const canAddSlot = !stationContainsPoint;
  const canAddPoint = !station.positions.length || (station.positions.length > 0 && stationContainsPoint);

  const [selectedPositionType, setSelectedPositionType] = useState<StationPositionTypeAndAll>('all');

  const allPositions = useMemo(() => {
    const availablePositions = getAllPositions({
      canAddPoints: canAddPoint,
      canAddSlots: canAddSlot,
      selectedPositionType,
    });

    const stationsPositionsIds = new Set(station.positions.map((p) => p.id));

    const allPositionsWithoutAlreadyAdded = availablePositions.filter((position) => {
      return !stationsPositionsIds.has(position.id);
    });

    return allPositionsWithoutAlreadyAdded;
  }, [canAddPoint, canAddSlot, selectedPositionType, station.positions]);

  const handleChangedPositionType = useCallback(
    (
      e: SelectChangeEvent<
        ShapeTypes.PointShape | ShapeTypes.StockZoneShape | ShapeTypes.RackShape | SlotTypes | 'all' | 'conveyors'
      >
    ) => {
      const newValue = e.target.value;
      if (!isStationPositionTypeAndAll(newValue)) {
        // eslint-disable-next-line no-console
        console.error('Invalid position type', newValue);

        return;
      }

      setSelectedPositionType(newValue);
    },
    [setSelectedPositionType]
  );

  const [keyAutocomplete, setKeyAutocomplete] = useState(0);
  const [valueToAdd, setValueToAdd] = useState<StationPositionWithName | string | null>(null);
  const handleChangePositionToAdd = useCallback((value: string | StationPositionWithName | null) => {
    setValueToAdd(value);
  }, []);

  const allPositionsThatMatches = useMemo(() => {
    if (typeof valueToAdd !== 'string' && valueToAdd !== null) return null;

    const valueToAddLowercase = (valueToAdd ?? '').toLowerCase();
    const allPositionsThatMatchesTmp = valueToAddLowercase.length
      ? allPositions.filter((position) => {
          return position.name.toLowerCase().includes(valueToAddLowercase);
        })
      : allPositions;

    const pointInThePositions = allPositionsThatMatchesTmp.some((position) => {
      return position.type === ShapeTypes.PointShape;
    }, []);
    const slotInThePositions = allPositionsThatMatchesTmp.some((position) => {
      return (
        position.type === ShapeTypes.StockZoneShape ||
        position.type === ShapeTypes.RackShape ||
        position.type === SlotTypes.Slot ||
        position.type === SlotTypes.StockLine
      );
    });
    const allPositionsThatMatchesTmp2 = pointInThePositions
      ? allPositionsThatMatchesTmp.filter((position) => {
          return position.type === ShapeTypes.PointShape;
        })
      : allPositionsThatMatchesTmp;
    const allPositionsThatMatchesTmp3 = slotInThePositions
      ? allPositionsThatMatchesTmp.filter((position) => {
          return (
            position.type === ShapeTypes.StockZoneShape ||
            position.type === ShapeTypes.RackShape ||
            position.type === SlotTypes.Slot ||
            position.type === SlotTypes.StockLine
          );
        })
      : allPositionsThatMatchesTmp2;

    return allPositionsThatMatchesTmp3;
  }, [allPositions, valueToAdd]);

  const handleAddPosition = useCallback(() => {
    if (!valueToAdd) return;
    if (typeof valueToAdd !== 'object') {
      return;
    }

    const { id, type } = valueToAdd;

    const position: StationPosition = {
      id,
      type,
    };

    dispatch(addPositionToStation({ stationId: station.id, position }));

    setValueToAdd(null);
    setKeyAutocomplete((k) => k + 1);
  }, [dispatch, station.id, valueToAdd]);

  const handleAllPositionsThatMatches = useCallback(() => {
    if (!allPositionsThatMatches) return;

    const positions = allPositionsThatMatches.map((position) => {
      return {
        id: position.id,
        type: position.type,
      };
    });

    dispatch(
      addSeveralPositionsToStation({
        stationId: station.id,
        positions,
      })
    );

    setValueToAdd(null);
    setKeyAutocomplete((k) => k + 1);
  }, [allPositionsThatMatches, dispatch, station.id]);

  const handleKeyUpAutoComplete = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = e.currentTarget.querySelector('input');
      const value = `${input?.value}`;

      if (typeof value === 'string') handleChangePositionToAdd(value);

      if (e.key === 'Enter') {
        handleAddPosition();
      }
    },
    [handleAddPosition, handleChangePositionToAdd]
  );

  return (
    <>
      <FormControl
        fullWidth
        size="small"
        sx={{
          marginTop: theme.spacing(2),
        }}
      >
        <InputLabel id="position-type-to-add-label">
          Position type
          <HelpIconTooltip
            title={'A station can not have points and the other elements at the same time'}
          ></HelpIconTooltip>
        </InputLabel>
        <Select
          labelId="position-type-to-add-label"
          id="position-type-to-add"
          value={selectedPositionType}
          label={
            <Box component={'div'}>
              Position type
              <HelpIconTooltip
                title={'A station can not have points and the other elements at the same time'}
              ></HelpIconTooltip>
            </Box>
          }
          onChange={handleChangedPositionType}
          size="small"
        >
          {availablePositionTypes.map((positionType) => {
            const isSlot =
              positionType === SlotTypes.Slot ||
              positionType === SlotTypes.StockLine ||
              positionType === ShapeTypes.StockZoneShape ||
              positionType === ShapeTypes.RackShape ||
              positionType === 'conveyors';
            const isPoint = positionType === ShapeTypes.PointShape;

            const disabled = (isSlot && !canAddSlot) || (isPoint && !canAddPoint);

            return (
              <MenuItem key={positionType} value={positionType} disabled={disabled}>
                {stationPositionTypeToLabel(positionType)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Stack
        direction="row"
        spacing={1}
        sx={{
          marginTop: theme.spacing(1),
        }}
      >
        <Autocomplete
          key={keyAutocomplete.toString()}
          id="position-to-add"
          fullWidth
          size="small"
          options={allPositions}
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyUp={(e) => {
                startTransition(() => {
                  handleKeyUpAutoComplete(e);
                });
              }}
              label="Position Name"
            />
          )}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          onChange={(event, value) => {
            startTransition(() => {
              handleChangePositionToAdd(value);
            });
          }}
          freeSolo
        />

        <Tooltip title="Add position">
          <Box component={'span'}>
            <IconButton
              aria-label="add"
              onClick={handleAddPosition}
              disabled={!valueToAdd || typeof valueToAdd !== 'object'}
            >
              <AddCircleIcon />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip
          title={
            allPositionsThatMatches?.length
              ? `Add the ${allPositionsThatMatches.length} positions that match`
              : 'Add all positions that matches'
          }
        >
          <Box component={'span'}>
            <IconButton
              aria-label="add"
              onClick={handleAllPositionsThatMatches}
              disabled={!allPositionsThatMatches || !allPositionsThatMatches.length}
            >
              <Queue />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
    </>
  );
}

function stationPositionTypeToLabel(positionType: StationPositionTypeAndAll): string {
  switch (positionType) {
    case 'all': {
      return 'All';
    }

    case ShapeTypes.PointShape: {
      return 'Points';
    }

    case ShapeTypes.RackShape: {
      return 'Racks';
    }

    case 'conveyors': {
      return 'Conveyors';
    }

    case ShapeTypes.StockZoneShape: {
      return 'Stock zones';
    }

    case SlotTypes.Slot: {
      return 'Slots';
    }

    case SlotTypes.StockLine: {
      return 'Stock lines';
    }
  }

  assert<Equals<typeof positionType, never>>();
}
