export enum DialogTypes {
  ImageScaling = 'image-scaling',
  TranslateCircuit = 'translate-circuit',
  RotateCircuit = 'rotate-circuit',
  RackAnalysis = 'rack-analysis',
  Share = 'share',
  Settings = 'settings',
  Layers = 'layers',
  SearchShape = 'search-shape',
  SearchShapeResultSelection = 'search-shape-result-selection',
  StockZoneSettings = 'stock-zone-settings',
  Welcome = 'welcome',
  RackEdition = 'rack-edition',
  RobotData = 'robot-data',
  AccessRequired = 'access-required',
  ProjectSettings = 'project-settings',
  SimulationReport = 'simulation-report',
  CreateProject = 'create-project',
  UpdateBattery = 'update-battery',
  UpdateOPCThresholds = 'update-OPC-thresholds',
  ExitRunningSimulation = 'exit-running-simulation',
  UpdateIntervalThroughput = 'update-interval-throughput',
  UpdateSchedulerConfig = 'update-scheduler-config',
  CreateNewStationFromRightClick = 'create-new-station-from-right-click',
  None = 'none',
}
