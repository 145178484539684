import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BackspaceIcon from '@mui/icons-material/Backspace';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Stack } from '@mui/system';
import { addDeviceAction, saveCircuitToHistoryAction } from 'actions/circuit';
import { saveDeviceAction } from 'actions/devices';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useSdkVersionOk } from 'components/utils/use-sdk-version-ok';
import { isEqual } from 'lodash';
import type { CircuitDevice, DeviceType, ModbusType, NetworkType, PinType } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { isComboxVersionType, isDeviceType, isNetworkType } from 'models/circuit.guard';
import type { DeviceData } from 'models/devices';
import { deviceMaxFrequency, deviceMinFrequency, deviceStepFrequency, devicesData } from 'models/devices';
import { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { LoadedDevice } from 'reducers/circuit/state';
import { CircuitService } from 'services/circuit.service';
import { useAppDispatch, useAppSelector } from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { useDebouncedCallback } from 'use-debounce';
import { AnimatedIcon } from 'utils/animated-icon';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { MIN_VERSION_NEW_EXPORT_DEVICES, frequencyDefaultValue } from 'utils/config';
import { theme } from 'utils/mui-theme';
import { PreferencesService, minimumSDKVersion } from 'utils/preferences';
import { PropertiesComponent } from './properties-component';

const pinNameToolTooltip = 'This an alias for the pin, it lets you remember to what this pin is connected.';

/**
 * Generate a placeholder name for a pin
 * @param pinIndex the index of the pin
 * @returns the generated placeholder name
 */
function getPlaceholderPinName(pinIndex: number): string {
  return `Alias ${pinIndex}`;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },

    helperText: {
      maxWidth: 'fit-content',
      textAlign: 'center',
    },
  })
);

const defaultModbusType: ModbusType = 'Bit';

interface DevicePropertiesProps {
  deviceId: string;
}

export const DeviceProperties = ({ deviceId }: DevicePropertiesProps): JSX.Element => {
  const device = useAppSelector((state) => state.circuit.present.devices.entities[deviceId]) as
    | LoadedDevice
    | undefined;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const comboxVerionsList = ['Standard', 'Extended', 'Button'];
  const networkList = ['Standalone', 'Gateway', 'Device'];
  const deviceEntities = useAppSelector((state) => state.circuit.present.devices.entities);

  const isSdkVersionOk = useSdkVersionOk(minimumSDKVersion);
  const deviceEntitiesArray = useMemo(() => Object.values(deviceEntities), [deviceEntities]);
  const gatewayDevices = useMemo(
    () => deviceEntitiesArray.filter((device) => device.properties.network === 'Gateway'),
    [deviceEntitiesArray]
  );

  const getAssociatedComboxGen2Devices = useCallback(
    (deviceId: string): LoadedDevice[] => {
      return deviceEntitiesArray.filter((device) => device.properties.gateway === deviceId);
    },
    [deviceEntitiesArray]
  );

  const comboxGen2GatewayAssociatedDevices = useMemo(
    () => getAssociatedComboxGen2Devices(deviceId),
    [deviceId, getAssociatedComboxGen2Devices]
  );

  const isMaxNumbOfAssociatiedDevicesReached = comboxGen2GatewayAssociatedDevices.length > 11;

  const displayNames = useMemo(
    () =>
      gatewayDevices.map((device) => {
        if (device?.id) {
          const associatedDevices = getAssociatedComboxGen2Devices(device.id.toString());

          if (associatedDevices.length > 11) {
            return undefined;
          }

          return device.properties.displayName;
        }

        return undefined;
      }),
    [gatewayDevices, getAssociatedComboxGen2Devices]
  );

  const displayNameFiltered = displayNames.filter((item) => typeof item === 'string');

  const [, setLoraId] = useState(device?.properties.loraID || '');
  const [inputValue, setInputValue] = useState(device?.properties.loraID || '');
  const [errorLoraId, setErrorLoraId] = useState('');

  const handleChangeInputValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  const checkLoraId = useCallback(
    (input: number): number => {
      const deviceNetworkType = device?.properties?.network;

      if (deviceNetworkType === 'Device') {
        // range between 1 and 15
        return Math.min(Math.max(input, 1), 15);
      } else if (deviceNetworkType === 'Gateway') {
        // value should be equal or greater than 100
        return Math.max(input, 100);
      }

      return 0;
    },
    [device?.properties?.network]
  );

  const loraIdSet = useMemo(() => {
    const loraIdSet = new Set<string>();
    Object.values(deviceEntities).forEach((d: LoadedDevice) => {
      if (d.properties.deviceType !== 'comboxGen2' || d.id === deviceId) return;

      const loraId = d.properties.loraID;
      if (!loraId || !loraId.length) {
        return;
      }

      if (loraIdSet.has(loraId)) {
        return;
      }

      loraIdSet.add(loraId);
    });

    return loraIdSet;
  }, [deviceEntities, deviceId]);

  const handleValidateAndSetLoraId = useCallback(() => {
    if (!device) return;
    const deviceNetworkType = device?.properties?.network;

    if (inputValue === '') {
      dispatch(
        saveDeviceAction({
          id: device.id,
          properties: { ...device.properties, loraID: '' },
        })
      );
      setLoraId('');
      setErrorLoraId('');
    } else if (!isNaN(Number(inputValue)) && Number(inputValue) >= 0) {
      const validatedInput = checkLoraId(Number(inputValue));
      const stringInput = validatedInput.toString();

      if (Number(inputValue) !== validatedInput) {
        if (deviceNetworkType === 'Device') {
          setErrorLoraId('Invalid input, the ID should be a unique number from 1 to 15.');
        } else if (deviceNetworkType === 'Gateway') {
          setErrorLoraId('Invalid input, the ID should be a unique number starting from 100 and higher.');
        } else {
          setErrorLoraId('Invalid input please read the informations');
        }
      } else {
        if (loraIdSet.has(stringInput)) {
          setErrorLoraId('This ID is already used by another device.');

          return;
        }

        dispatch(
          saveDeviceAction({
            id: device.id,
            properties: { ...device.properties, loraID: stringInput },
          })
        );
        setLoraId(stringInput);
        setErrorLoraId('');
      }
    } else {
      setErrorLoraId('Invalid input please read the informations');
    }
  }, [checkLoraId, device, dispatch, inputValue, loraIdSet]);

  const ioAddressesFormRef = useRef<HTMLFormElement>(null);

  const ioValueMode = device?.properties.modbusType;
  const [ipIndex, setIpIndex] = useState<string>(device?.properties.gateway || '');
  const refInputPort = useRef<HTMLFormElement>(null);
  const refInputPinsIn = useRef<HTMLFormElement>(null);
  const refInputPinsOut = useRef<HTMLFormElement>(null);

  const [deviceName, setDeviceName] = useState(device?.properties.name || '');
  const [ipDevice, setIpDevice] = useState(() => {
    const ipIndexExists = deviceEntitiesArray.some((device) => device.id === ipIndex);

    if (!ipIndexExists && device?.properties.network === 'Device') {
      setIpIndex('');
      dispatch(
        saveDeviceAction({
          id: device.id,
          properties: {
            ...device.properties,
            IP: '',
            gateway: '',
          },
        })
      );
    }

    return device?.properties.IP || '';
  });

  const [pinsIn, setPinsIn] = useState(device?.properties.pinsIn || []);
  const [pinsOut, setPinsOut] = useState(device?.properties.pinsOut || []);

  const [comboxVersion, setComboxVersion] = useState<string>(device?.properties.comboxVersion || 'Standard');
  const [network, setNetwork] = useState<string>(device?.properties.network || 'Gateway');

  /** With both Network and ComboxVersion, we can create various ComboxGen2 configurations. However, there are specific conditions for exporting the hardware properties in the XML file*/
  const handleNetworkChange = useCallback(
    (event: SelectChangeEvent<string>): void => {
      if (!device || !device.id) {
        throw new Error('Device must be added or SDK version is too old');
      }

      const newNetwork = event.target.value;

      if (!isNetworkType(newNetwork)) {
        // eslint-disable-next-line no-console
        console.error(`Wrong type for the network: ${newNetwork}`);

        return;
      }

      dispatch(
        saveDeviceAction({
          id: device.id,
          properties: {
            ...device.properties,
            network: newNetwork,
          },
        })
      );
      setNetwork(newNetwork);
    },
    [device, dispatch]
  );
  /** With both Network and ComboxVersion, we can create various ComboxGen2 configurations. However, there are specific conditions for exporting the hardware properties in the XML file*/
  const handleComboxVersionChange = useCallback(
    (event: SelectChangeEvent<string>): void => {
      if (!device || !device.id) {
        throw new Error('Device must be added or SDK version is too old');
      }

      const newComboxVersion = event.target.value;

      if (!isComboxVersionType(newComboxVersion)) {
        // eslint-disable-next-line no-console
        console.error(`Wrong type for the combox version: ${newComboxVersion}`);

        return;
      }

      setComboxVersion(newComboxVersion);

      const updatedDeviceProperties = {
        ...device.properties,
        comboxVersion: newComboxVersion,
        network: network as NetworkType,
      };

      dispatch(saveDeviceAction({ id: device.id, properties: updatedDeviceProperties }));
    },
    [device, network, dispatch]
  );

  const ioAddresses = useMemo(() => {
    if (device?.properties.deviceType !== 'modbusDevice') return;

    const ioAddressesForm = ioAddressesFormRef.current;
    if (!ioAddressesForm) return;

    if (ioValueMode === 'Bit') {
      const firstIAdresses = parseInt((ioAddressesForm.elements['firstIAdresses']?.value as string) || '0', 10);
      const secondIAdresses = parseInt((ioAddressesForm.elements['secondIAdresses']?.value as string) || '0', 10);
      const newIoAdresses = [firstIAdresses, secondIAdresses, 0, 0];

      return newIoAdresses;
    } else if (ioValueMode === 'Register') {
      const firstIAdresses = parseInt((ioAddressesForm.elements['firstIAdresses']?.value as string) || '0', 10);
      const secondIAdresses = parseInt((ioAddressesForm.elements['secondIAdresses']?.value as string) || '0', 10);
      const newIoAdresses = [0, 0, firstIAdresses, secondIAdresses];

      return newIoAdresses;
    }
  }, [device?.properties.deviceType, ioValueMode]);

  const handleChangeIoValue = useCallback(
    (event: React.MouseEvent<HTMLElement>, newIoValue: string) => {
      if (!device || !device.id) {
        throw new Error('Device must be added');
      }

      dispatch(
        saveDeviceAction({
          id: device.id,
          properties: { ...device.properties, modbusType: newIoValue as ModbusType, ioAddresses },
        })
      );
    },
    [device, dispatch, ioAddresses]
  );

  const [errorIP, setErrorIP] = useState(false);

  const [errorName, setErrorName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');
  const updatePropertiesDebounced = useDebouncedCallback(() => {
    if (!device) return;

    let name = deviceName;
    if (
      !name ||
      !areAllShapeNamesUnique([name], [device.id as string], {
        ignoreDuplicatesBefore: true,
      })
    ) {
      name = suggestedName;
    }

    dispatch(saveCircuitToHistoryAction());

    dispatch(
      saveDeviceAction({
        id: device.id,
        properties: {
          ...device.properties,
          ioAddresses,
          name,
        },
      })
    );
  }, 400);

  const onNameChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;
      if (newName?.length < 50) {
        setDeviceName(newName);
      }

      startTransition(() => {
        const isNameAlreadyUsed = !areAllShapeNamesUnique([newName], [device?.id as string], {
          ignoreDuplicatesBefore: true,
        });

        if (!newName || isNameAlreadyUsed) {
          setErrorName(true);
          setSuggestedName(CircuitService.generateDifferentName(newName, { shapeIdsToIgnore: [device?.id as string] }));
        } else {
          setErrorName(false);
        }

        updatePropertiesDebounced();
      });
    },
    [updatePropertiesDebounced, device]
  );

  const setSuggestedNameHandler = useCallback(() => {
    setDeviceName(suggestedName);
    setErrorName(false);

    updatePropertiesDebounced();
  }, [suggestedName, updatePropertiesDebounced]);

  const locked = !!device?.properties?.locked;

  const deviceType = device?.properties.deviceType;

  const deviceList = deviceType !== undefined && deviceType === 'comboxGen2';

  const otherDeviceTypesAvailable: DeviceType[] = useMemo(() => {
    return ['mobileDockingStation', 'smartCharger', 'modbusDevice', 'comboxGen2'];
  }, []);

  const gen1ComboxList = ['3BTCombox', 'IOECombox'];

  const [openMenuOtherDeviceType, setOpenMenuOtherDeviceType] = useState(false);

  const [otherDevice, setOtherDevice] = useState<DeviceType>(
    deviceList
      ? 'comboxGen2'
      : deviceType && otherDeviceTypesAvailable.includes(deviceType)
        ? deviceType
        : 'modbusDevice'
  );
  const devicesOption: [DeviceType, DeviceType, DeviceType] = useMemo(() => {
    return ['IOECombox', '3BTCombox', otherDevice];
  }, [otherDevice]);

  const refOtherOption = useRef<null | HTMLElement>(null);
  const refInputDisplayName = useRef<null | HTMLInputElement>(null);

  const checkName = useCallback(
    (newName: string) => {
      return newName && newName.length < 50 && areAllShapeNamesUnique([newName], [device?.id as string]);
    },
    [device?.id]
  );
  const checkIP = useCallback(async (ip: string) => {
    return (await import('is-ip')).isIP(ip);
  }, []);

  const handleSaveDevice = useCallback(
    async (newDeviceProperties: Partial<CircuitDevice['properties']> = {}) => {
      if (!device) return;

      let ip = (newDeviceProperties.IP || ipDevice).trim();
      if (!(await checkIP(ip))) {
        ip = device.properties.IP;
        setErrorIP(true);
      }

      let newName = newDeviceProperties.name || deviceName;
      if (!checkName(newName)) newName = device.properties.name;

      const displayName = refInputDisplayName.current?.value || device.properties.displayName;
      startTransition(() => {
        dispatch(saveCircuitToHistoryAction());
        dispatch(
          saveDeviceAction({
            id: device.id,
            properties: {
              ...device.properties,
              name: newName,
              displayName,
              ioAddresses,
              IP: ip,
            },
          })
        );
      });
    },
    [checkIP, checkName, device, deviceName, dispatch, ioAddresses, ipDevice]
  );

  const handleUpdateIoValues = useCallback(() => {
    // Updating ioAddresses
    const ioAddressesForm = ioAddressesFormRef.current;
    let ioAddressesValue: number[] | undefined;
    if (ioAddressesForm) {
      if (ioValueMode === 'Bit') {
        const firstIAdresses = ioAddressesForm.elements['firstIAdresses']?.value as string | undefined;
        const secondIAdresses = ioAddressesForm.elements['secondIAdresses']?.value as string | undefined;
        ioAddressesValue = [Number(firstIAdresses) ?? '0', Number(secondIAdresses) ?? '0', 0, 0];
      } else if (ioValueMode === 'Register') {
        const firstIAdresses = ioAddressesForm.elements['firstIAdresses']?.value as string | undefined;
        const secondIAdresses = ioAddressesForm.elements['secondIAdresses']?.value as string | undefined;
        ioAddressesValue = [0, 0, Number(firstIAdresses) ?? '0', Number(secondIAdresses) ?? '0'];
      }
    }

    if (device && device.id) {
      const properties = { ...device.properties, ioAddresses: ioAddressesValue };

      let hasChanges = false;
      const displayPort = (refInputPort.current?.value as number) || device.properties.port;
      if (displayPort && !isEqual(displayPort, device.properties.port)) {
        properties.port = Number(displayPort);
        hasChanges = true;
      }

      if (ioAddresses && !isEqual(ioAddresses, device.properties.ioAddresses)) {
        properties.ioAddresses = ioAddresses;
        hasChanges = true;
      }

      if (hasChanges) {
        dispatch(saveCircuitToHistoryAction());
        dispatch(
          saveDeviceAction({
            id: device.id,
            properties,
          })
        );
      }
    }
  }, [ioValueMode, device, dispatch, ioAddresses]);

  const handleChangeDeviceType = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newDeviceType = (e.target as any)?.value as string;
      if (!newDeviceType) return;
      if (!device) return;

      if (isDeviceType(newDeviceType)) {
        if (newDeviceType === otherDevice) {
          setOpenMenuOtherDeviceType(true);
        }

        let modbusType: ModbusType | undefined = undefined;
        if (newDeviceType === 'modbusDevice') {
          modbusType = defaultModbusType;
        }

        dispatch(saveCircuitToHistoryAction());
        dispatch(
          saveDeviceAction({
            id: device.id,
            properties: {
              ...device.properties,
              deviceType: newDeviceType,
              modbusType: modbusType,
              network: undefined,
              comboxVersion: undefined,
            },
          })
        );

        if (newDeviceType === 'modbusDevice') {
          handleUpdateIoValues();
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(`Device type ${newDeviceType} is not valid`);
      }
    },
    [device, dispatch, handleUpdateIoValues, otherDevice]
  );

  const handleIpChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!device) {
        // eslint-disable-next-line no-console
        console.error('No device found');

        return;
      }

      const newIp = e.target.value;
      setIpDevice(newIp); // Update ipDevice state regardless of whether the IP is valid or not

      const isValidIp = await checkIP(newIp);
      if (!isValidIp) {
        setErrorIP(true);

        return;
      }

      setErrorIP(false); // If the IP is valid, set errorIP state to false

      const selectedDevices = deviceEntitiesArray.filter(
        (device) => String(device.properties.gateway) === String(deviceId)
      );
      if (selectedDevices.length === 0) {
        // eslint-disable-next-line no-console
        console.error('No devices found with the match id');

        return;
      }

      dispatch(
        saveDeviceAction({
          id: device.id,
          properties: {
            ...device.properties,
            IP: newIp,
          },
        })
      );
      selectedDevices.forEach((selectedDevice) => {
        dispatch(
          saveDeviceAction({
            id: selectedDevice.id,
            properties: {
              ...selectedDevice.properties,
              IP: newIp,
            },
          })
        );
      });
    },
    [device, checkIP, deviceEntitiesArray, deviceId, dispatch]
  );
  const handleDeviceIpName = useCallback(
    async (e: SelectChangeEvent<string>) => {
      if (!device) {
        // eslint-disable-next-line no-console
        console.error('No device found');

        return;
      }

      const newIpIndex = e.target.value;

      setIpIndex(newIpIndex);
      const selectedDevice = deviceEntitiesArray.find((device) => device.properties.displayName === newIpIndex);

      if (!selectedDevice) {
        // eslint-disable-next-line no-console
        console.error('No device found with the selected display name');

        return;
      }

      const newIp = selectedDevice.properties.IP;
      const isValidIp = await checkIP(newIp);
      if (!isValidIp) {
        setErrorIP(true);

        return;
      }

      dispatch(
        saveDeviceAction({
          id: device.id,
          properties: {
            ...device.properties,
            IP: newIp,
            gateway: selectedDevice.id as string,
          },
        })
      );
    },
    [device, deviceEntitiesArray, checkIP, dispatch]
  );

  const handleChangeOtherDeviceType = useCallback(
    (newOtherDeviceType: string) => {
      if (!device) return;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (isDeviceType(newOtherDeviceType)) {
        setOtherDevice(newOtherDeviceType);

        dispatch(saveCircuitToHistoryAction());

        let modbusType: ModbusType | undefined = undefined;
        if (newOtherDeviceType === 'modbusDevice') {
          modbusType = defaultModbusType;
        }

        if (newOtherDeviceType === 'comboxGen2') {
          dispatch(
            saveDeviceAction({
              id: device.id,
              properties: {
                ...device.properties,
                ioAddresses,
                network: device.properties.network ?? 'Gateway',
                comboxVersion: device.properties.comboxVersion ?? 'Standard',
                deviceType: newOtherDeviceType,
                modbusType: undefined,
              },
            })
          );

          setOpenMenuOtherDeviceType(false);

          return;
        }

        dispatch(
          saveDeviceAction({
            id: device.id,
            properties: {
              ...device.properties,
              deviceType: newOtherDeviceType,
              network: undefined,
              comboxVersion: undefined,
              modbusType: modbusType,
            },
          })
        );

        setOpenMenuOtherDeviceType(false);
      } else {
        // eslint-disable-next-line no-console
        console.error(`Device type ${newOtherDeviceType} is not valid (handleChangeOtherDeviceType)`);
      }
    },
    [device, dispatch, ioAddresses]
  );

  const updateNbPinsIfNeeded = useCallback(
    (pins: PinType[], expectedNbPins: number, setFn: (value: React.SetStateAction<PinType[]>) => void) => {
      if (expectedNbPins > pinsIn.length) {
        // pins are missing
        const nbMissingPins = expectedNbPins - pins.length;
        const newPins = [...pins];
        for (let i = 0; i < nbMissingPins; i++) {
          newPins.push({
            name: getPlaceholderPinName(newPins.length + 1),
          });
        }

        setFn(newPins);
      } else if (expectedNbPins < pins.length) {
        // too many pins
        const newPins = [...pins];
        newPins.length = expectedNbPins;
        setFn(newPins);
      }
    },
    [pinsIn.length]
  );

  const calculateDefaultValueIoAdresses = useCallback(() => {
    if (ioValueMode === 'Register') {
      return device?.properties?.ioAddresses?.[2];
    } else if (ioValueMode === 'Bit') {
      return device?.properties?.ioAddresses?.[0];
    }

    return;
  }, [ioValueMode, device?.properties?.ioAddresses]);

  const calculateDefaultValueIoAdressesSecond = useCallback(() => {
    if (ioValueMode === 'Register') {
      return device?.properties?.ioAddresses?.[3];
    } else if (ioValueMode === 'Bit') {
      return device?.properties?.ioAddresses?.[1];
    }

    return;
  }, [ioValueMode, device?.properties?.ioAddresses]);

  useEffect(() => {
    if (device?.properties.IP) setIpDevice(device.properties.IP);
  }, [device?.properties.IP]);
  useEffect(() => {
    if (device?.properties.pinsIn) setPinsIn(device.properties.pinsIn);
  }, [device?.properties.pinsIn]);
  useEffect(() => {
    if (device?.properties.pinsOut) setPinsOut(device.properties.pinsOut);
  }, [device?.properties.pinsOut]);

  useEffect(() => {
    if (
      device &&
      device.properties.pinsIn &&
      device.properties.pinsIn.length &&
      device.properties.pinsIn.length !== pinsIn.length
    ) {
      dispatch(
        saveDeviceAction({
          id: device.id,
          properties: {
            ...device.properties,
            pinsIn: pinsIn,
          },
        })
      );
    }
  }, [device, dispatch, pinsIn]);
  useEffect(() => {
    if (
      device &&
      device.properties.pinsOut &&
      device.properties.pinsOut.length &&
      device.properties.pinsOut.length !== pinsOut.length
    ) {
      dispatch(
        saveDeviceAction({
          id: device.id,
          properties: {
            ...device.properties,
            pinsOut: pinsOut,
          },
        })
      );
    }
  }, [device, dispatch, pinsOut]);
  useEffect(() => {
    if (!device || !deviceType) return;
    if (!devicesData[deviceType]) return;
    let deviceData = devicesData[deviceType];
    if (devicesData[deviceType]) {
      if (device.properties.comboxVersion && device.properties.comboxVersion in deviceData) {
        deviceData = deviceData[device.properties.comboxVersion] as DeviceData;
      }

      const expectedNbPinsIn = 'nbPinsIn' in deviceData ? deviceData.nbPinsIn : null;

      if (typeof expectedNbPinsIn === 'number' && expectedNbPinsIn !== pinsIn.length) {
        updateNbPinsIfNeeded(pinsIn, expectedNbPinsIn, setPinsIn);
      }

      if (device.properties.comboxVersion && device.properties.comboxVersion in deviceData) {
        deviceData = deviceData[device.properties.comboxVersion] as DeviceData;
      }

      const expectedNbPinsOut = 'nbPinsOut' in deviceData ? deviceData.nbPinsOut : null;

      if (typeof expectedNbPinsOut === 'number' && expectedNbPinsOut !== pinsOut.length) {
        updateNbPinsIfNeeded(pinsOut, expectedNbPinsOut, setPinsOut);
      }
    }
  }, [device, deviceType, pinsIn, pinsOut, updateNbPinsIfNeeded]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!device) return;

      if (e.key === 'Enter') {
        handleSaveDevice();
        const selectedDevice = deviceEntitiesArray.find(
          (device) => String(device.properties.gateway) === String(device.id)
        );
        if (selectedDevice) {
          dispatch(
            saveDeviceAction({
              id: selectedDevice.id,
              properties: {
                ...selectedDevice.properties,
                IP: ipDevice,
              },
            })
          );
        }
      } else {
        if (e.key === 'Escape') {
          setDeviceName(device.properties.name);
          setIpDevice(device.properties.IP);

          e.currentTarget.blur();
        }

        if (errorIP) setErrorIP(false);
      }
    },
    [device, errorIP, handleSaveDevice, ipDevice, dispatch, deviceEntitiesArray]
  );

  const disableBlur = useRef<boolean>(false);

  const handleUpdatePin = useCallback(
    (pin: PinType, index: number, type: 'in' | 'out') => {
      if (!device) return;

      if (!pin.name) {
        pin.name = getPlaceholderPinName(index + 1);
      }

      const newPins = type === 'in' ? [...pinsIn] : [...pinsOut];
      newPins[index] = pin;

      dispatch(
        saveDeviceAction({
          ...device,
          properties: {
            ...device.properties,
            pinsIn: type === 'in' ? newPins : pinsIn,
            pinsOut: type === 'out' ? newPins : pinsOut,
          },
        })
      );
    },
    [device, dispatch, pinsIn, pinsOut]
  );

  let deviceData: (typeof devicesData)[keyof typeof devicesData] | undefined;
  if (deviceType) {
    deviceData = devicesData[deviceType];
  }

  const askNbInputPins = !!(deviceData && 'nbPinsIn' in deviceData && deviceData.nbPinsIn === 'user');
  const askNbOutputPins = !!(deviceData && 'nbPinsOut' in deviceData && deviceData.nbPinsOut === 'user');

  const askInputCustomLabels = !!(deviceData && deviceData['Button'] && comboxVersion === 'Button');
  const askOutputCustomLabels = !!(deviceData && deviceData['Button'] && comboxVersion === 'Button');

  const askNbPins = askNbInputPins || askNbOutputPins;
  const displayKeepWritingOutputs = deviceType === 'modbusDevice';
  const [displayOtherSection, setDisplayOtherSection] = useState(false);

  const handleChangeKeepWritingOutputs = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!device) return;
      dispatch(
        saveDeviceAction({
          ...device,
          properties: {
            ...device.properties,
            ioAddresses,
            keepWritingOutputs: checked,
          },
        })
      );
    },
    [device, dispatch, ioAddresses]
  );

  const frequencyInputRef = useRef<HTMLInputElement | null>(null);
  const NEW4XVersion = PreferencesService.getNEW4XCoreVersion();
  const getDisplayName = useCallback(
    (id: string) => {
      const device = deviceEntitiesArray.find((device) => device.id === id || device.properties.name === id);

      return device?.properties.displayName || '';
    },
    [deviceEntitiesArray]
  );

  const portDevaultValue = '502';
  const manageDevicePrefEnabled = useAppSelector((state) => state.project.enableDevicePrefManagement);

  const displayWarningNew4XVersion =
    manageDevicePrefEnabled && (NEW4XVersion === undefined || NEW4XVersion < MIN_VERSION_NEW_EXPORT_DEVICES);
  const displayWarningIfDevicesManagementDisabled = !manageDevicePrefEnabled;
  const [lastCoordinates, setLastCoordinates] = useState(device?.geometry.coordinates);
  const networkTooltipText = (
    <div>
      <Typography variant="body2">
        How the combox communicates with the Robot Manager
        <br />
        <strong>Standalone:</strong> the combox is connected to the client network with an ethernet cable (RJ45).
        <br />
        <strong>Gateway:</strong> The combox is connected to the client network with an Ethernet cable (RH45) and
        broadcasts a LoRa wireless network. Comboxes with a device network will use this combox to communicate with the
        Robot Manager.
        <br />
        <strong>Device:</strong> The combox uses a combox gateway as a bridge to communicate with the Robot Manager.
      </Typography>
    </div>
  );

  const comboxVersionTooltipText = (
    <div>
      <Typography variant="body2">
        <strong>Extended:</strong> a combox with more input and output pins
        <br />
        <strong> 3 buttons:</strong> a combox with three buttons (with embedded LEDs) to act as a Human User Interface
        (HMI)
      </Typography>
    </div>
  );

  const loraIdTooltip = (
    <div>
      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
        Lora ID
      </Typography>
      <Typography variant="body2">
        A number to identify the gateways and devices in the Lora Network.
        <br />
        <strong>For devices:</strong> 1 ≤ Lora ID ≤ 15 (must be unique for every devices).
        <br />
        <strong>For gateways:</strong> Lora ID ≥ 100 (must be unique).
      </Typography>
    </div>
  );

  const ipDisabledForComboxGen2Device = device?.properties.network === 'Device';
  const networkWidthLoraId = ['Gateway', 'Device'];
  const networkWidthAssociatedDevice = ['Gateway'];
  const deviceWidthGateway = ['Device'];
  const comboxVersionSelectLabel = (
    <div>
      Combox Version <HelpIconTooltip title={comboxVersionTooltipText} />
    </div>
  );
  const networkSelectLabel = (
    <div>
      Network <HelpIconTooltip title={networkTooltipText} />
    </div>
  );

  const createAssociatedDevice = useCallback(() => {
    if (!device) return;

    const offsetX = 10;
    const newCoordinates = lastCoordinates ? [...lastCoordinates] : [];
    newCoordinates[0] += 5 * offsetX;
    setLastCoordinates(newCoordinates);

    dispatch(
      addDeviceAction({
        coord: newCoordinates,
        deviceType: 'comboxGen2',
        network: 'Device',
        comboxVersion: 'Standard',
        userAction: true,
        gateway: device.id as string,
        IP: device.properties.IP,
      })
    );
  }, [device, dispatch, lastCoordinates]);

  return !deviceId || !device ? (
    <></>
  ) : (
    <PropertiesComponent
      shape={device}
      shapeId={deviceId}
      shapeType={ShapeTypes.DeviceShape}
      sx={{
        maxWidth: 'min-content',
      }}
    >
      {displayWarningNew4XVersion ? (
        <Alert
          severity="warning"
          sx={{
            maxWidth: 'fit-content',
            marginBottom: theme.spacing(1),
          }}
        >
          The devices will be saved in your circuit file but not exported in the project because{' '}
          {NEW4XVersion === undefined
            ? `the NEW4X version used has not been detected (coreVersion file is missing)`
            : `the NEW4X version used it too old (version detected: ${NEW4XVersion}, minimum version: ${MIN_VERSION_NEW_EXPORT_DEVICES})`}
        </Alert>
      ) : (
        <></>
      )}
      {displayWarningIfDevicesManagementDisabled ? (
        <Alert
          severity="warning"
          sx={{
            maxWidth: 'fit-content',
            marginBottom: theme.spacing(1),
          }}
        >
          The devices preferences management is disabled. Thereby Road Editor will not export the devices parameters.
        </Alert>
      ) : (
        <></>
      )}
      {otherDevice === 'comboxGen2' && !isSdkVersionOk && !gen1ComboxList.includes(deviceType as string) ? (
        <Alert
          severity="warning"
          sx={{
            maxWidth: 'fit-content',
            marginBottom: theme.spacing(1),
          }}
        >
          Combox gen2 won't be exported in xml because the SDK version is too old (minimum version: {minimumSDKVersion})
        </Alert>
      ) : undefined}

      <ToggleButtonGroup
        size="small"
        color="primary"
        value={deviceType}
        onChange={handleChangeDeviceType}
        disabled={locked}
        fullWidth
        sx={{ width: '410px' }}
        exclusive
      >
        {devicesOption.map((type, deviceIndex) => (
          <ToggleButton
            key={type}
            value={type}
            sx={{
              textTransform: 'none',
            }}
            ref={deviceIndex === devicesOption.length - 1 ? (el) => (refOtherOption.current = el) : undefined}
            onClick={
              deviceIndex === devicesOption.length - 1 && type === deviceType
                ? (e) => {
                    e.stopPropagation();
                    setOpenMenuOtherDeviceType(true);
                  }
                : undefined
            }
          >
            {deviceTypeToDisplayDeviceType(type)}

            {deviceIndex === devicesOption.length - 1 && <ArrowDropDownIcon />}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {refOtherOption.current && (
        <Menu
          open={openMenuOtherDeviceType}
          anchorEl={refOtherOption.current}
          onClose={() => setOpenMenuOtherDeviceType(false)}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
        >
          {otherDeviceTypesAvailable.map((type) => (
            <MenuItem
              key={type}
              selected={otherDevice === type}
              onClick={() => {
                handleChangeOtherDeviceType(type);
              }}
            >
              {deviceTypeToDisplayDeviceType(type)}
            </MenuItem>
          ))}
        </Menu>
      )}

      {otherDevice === 'comboxGen2' && !gen1ComboxList.includes(deviceType as string) ? (
        <Box width="full" component="span">
          <FormControl fullWidth size="small" sx={{}}>
            <InputLabel htmlFor="Network">{networkSelectLabel}</InputLabel>
            <Select
              onChange={(e) => {
                handleNetworkChange(e);
              }}
              value={network}
              label={networkSelectLabel}
              inputProps={{
                name: 'Network',
                id: 'Network',
              }}
            >
              {networkList.map((network) => (
                <MenuItem key={network} value={network}>
                  {network}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: theme.spacing(2) }} size="small">
            <InputLabel htmlFor="Combox Version">{comboxVersionSelectLabel}</InputLabel>
            <Select
              value={comboxVersion}
              onChange={handleComboxVersionChange}
              label={comboxVersionSelectLabel}
              inputProps={{
                name: 'Combox Version',
                id: 'Combox Version',
                shrink: true,
              }}
            >
              {comboxVerionsList.map((version) => (
                <MenuItem key={version} value={version}>
                  {version}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {networkWidthLoraId.includes(network) && (
            <TextField
              size="small"
              fullWidth
              helperText={errorLoraId}
              error={!!errorLoraId || inputValue === '' || loraIdSet.has(inputValue)}
              required
              InputLabelProps={{
                shrink: true,
              }}
              label={
                <>
                  Lora ID <HelpIconTooltip title={loraIdTooltip} />
                </>
              }
              value={inputValue}
              onChange={handleChangeInputValue}
              onBlur={handleValidateAndSetLoraId}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleValidateAndSetLoraId();
                }
              }}
              margin="normal"
            />
          )}

          {networkWidthAssociatedDevice.includes(network) && (
            <Tooltip
              title={
                isMaxNumbOfAssociatiedDevicesReached ? 'You can only create 12 associated devices per gateway' : ''
              }
            >
              <Box component="span">
                <Button
                  sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                  variant="outlined"
                  onClick={createAssociatedDevice}
                  disabled={isMaxNumbOfAssociatiedDevicesReached}
                >
                  Add Associated Device <AddIcon />
                </Button>
              </Box>
            </Tooltip>
          )}
          {deviceWidthGateway.includes(network) && (
            <FormControl fullWidth size="small" sx={{ mt: '0.5rem', mb: '0.5rem' }}>
              <InputLabel htmlFor="Select available Gateway">Select available Gateway</InputLabel>
              <Select
                label="Select available Gateway"
                inputProps={{
                  name: 'Select available Gateway',
                  id: 'Select available Gateway',
                }}
                value={getDisplayName(ipIndex)}
                onChange={handleDeviceIpName}
              >
                {displayNames.length < 1 ? (
                  <MenuItem>There are no gateway devices; Please create one.</MenuItem>
                ) : displayNameFiltered.length < 1 ? (
                  <MenuItem>All gateways have already reached the maximum number of associated devices</MenuItem>
                ) : (
                  displayNames.map((displayName, index) => (
                    <MenuItem key={index} value={displayName}>
                      {displayName}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          )}
        </Box>
      ) : undefined}

      <TextField
        label={
          <>
            IP <HelpIconTooltip title="The IP address of the device in your network" />
          </>
        }
        value={ipDevice}
        fullWidth
        margin="normal"
        disabled={locked || ipDisabledForComboxGen2Device}
        size="small"
        onChange={(e) => handleIpChange(e as React.ChangeEvent<HTMLInputElement>)}
        onKeyDown={handleKeyPress}
        error={errorIP}
        helperText={errorIP ? 'Invalid IP address' : undefined}
        onBlur={() => !disableBlur.current && handleSaveDevice()}
        InputProps={{
          endAdornment:
            device?.properties.IP !== ipDevice ? (
              <InputAdornment position="end">
                <AnimatedIcon triggerOnClick={true} defaultAnimation="turn-on-itself" animationDuration={500}>
                  <Tooltip title="Restore the value to the previous value">
                    <Box component="span">
                      <IconButton
                        onClick={(e) => {
                          setErrorIP(false);
                          disableBlur.current = true;
                          setTimeout(() => {
                            setIpDevice(device.properties.IP);
                            setErrorIP(false);
                            disableBlur.current = false;
                          }, 500);
                        }}
                      >
                        <SettingsBackupRestoreIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </AnimatedIcon>
              </InputAdornment>
            ) : undefined,
        }}
      />

      <FormControl error={errorName}>
        <TextField
          value={deviceName}
          onChange={onNameChangeHandler}
          fullWidth
          margin="dense"
          label={
            <>
              Name{' '}
              <HelpIconTooltip title="The name of the device. The name used by the Balyo systems. Must be unique accross all shapes." />
            </>
          }
          disabled={locked}
          size="small"
          sx={{
            textDecoration: errorName ? 'line-through' : undefined,

            color: locked ? theme.palette.grey[300] : undefined,
            pointerEvents: locked ? 'none' : undefined,
          }}
        />
        <FormHelperText className={classes.helperText} error id="suggested-name-device">
          {errorName ? (
            <>
              Name already used, suggested name:{' '}
              <Box
                component="span"
                onClick={setSuggestedNameHandler}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {suggestedName}
              </Box>
            </>
          ) : (
            <></>
          )}
        </FormHelperText>
      </FormControl>

      <TextField
        label={
          <>
            Display Name{' '}
            <HelpIconTooltip title="The meaningful name of the device. The name displayed in the user interfaces." />
          </>
        }
        defaultValue={device?.properties.displayName ?? ''}
        size="small"
        margin="dense"
        disabled={locked}
        onBlur={(e) => handleSaveDevice()}
        inputRef={refInputDisplayName}
      />
      {askNbPins && otherDevice !== 'comboxGen2' ? (
        <TextField
          label={
            <>
              Port
              <HelpIconTooltip title={`Port of the device. By default this value is 502.`} />
            </>
          }
          defaultValue={device?.properties.port ?? portDevaultValue}
          size="small"
          margin="dense"
          onBlur={handleUpdateIoValues}
          inputRef={refInputPort}
        />
      ) : undefined}
      {askNbPins && otherDevice !== 'comboxGen2' ? (
        <Grid container spacing={1}>
          {askNbInputPins && (
            <Grid item xs={askNbOutputPins ? 6 : 12}>
              <TextField
                inputRef={refInputPinsIn}
                name="ioCount"
                label="Number of inputs"
                type="number"
                size="small"
                margin="dense"
                fullWidth
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 1,
                }}
                defaultValue={pinsIn.length}
                onBlur={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (!isNaN(value)) {
                    updateNbPinsIfNeeded(pinsIn, value, setPinsIn);
                  }
                }}
              />
            </Grid>
          )}

          {askNbOutputPins && (
            <Grid item xs={askNbInputPins ? 6 : 12}>
              <TextField
                inputRef={refInputPinsOut}
                label="Number of outputs"
                name="oCount"
                type="number"
                size="small"
                margin="dense"
                fullWidth
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 1,
                }}
                defaultValue={pinsOut.length}
                onBlur={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (!isNaN(value)) {
                    updateNbPinsIfNeeded(pinsOut, value, setPinsOut);
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      ) : undefined}

      <Grid container spacing={1}>
        {pinsIn.length > 0 ? (
          <Grid item xs={6}>
            <Box component="div">
              <Divider variant="middle">Pins Input</Divider>
            </Box>
            <Stack sx={{ maxHeight: '150px', overflowY: 'auto' }}>
              {pinsIn.map((pinIn, i) => {
                return (
                  <TextField
                    key={`${pinIn}-${i}`}
                    label={
                      <>
                        {askInputCustomLabels
                          ? devicesData.comboxGen2['Button'].inputCustomLabels[i]
                          : `Input ${i + 1}`}
                        <HelpIconTooltip title={pinNameToolTooltip} />
                      </>
                    }
                    defaultValue={pinIn.name}
                    size="small"
                    margin="dense"
                    disabled={locked}
                    onBlur={(e) => handleUpdatePin({ ...pinIn, name: e.target.value }, i, 'in')}
                    fullWidth
                  />
                );
              })}
            </Stack>
          </Grid>
        ) : (
          <Grid item xs={6}></Grid>
        )}

        {pinsOut.length > 0 ? (
          <Grid item xs={6}>
            <Box component="div">
              <Divider variant="middle">Pins Output</Divider>
            </Box>
            <Stack sx={{ maxHeight: '150px', overflowY: 'auto' }}>
              {pinsOut.map((pinOut, i) => {
                return (
                  <TextField
                    key={`${pinOut}-${i}`}
                    label={
                      <>
                        {askOutputCustomLabels
                          ? devicesData.comboxGen2['Button'].outputCustomLabels[i]
                          : `Output ${i + 1}`}
                        <HelpIconTooltip title={pinNameToolTooltip} />
                      </>
                    }
                    defaultValue={pinOut.name}
                    size="small"
                    margin="dense"
                    disabled={locked}
                    onBlur={(e) => handleUpdatePin({ ...pinOut, name: e.target.value }, i, 'out')}
                    fullWidth
                  />
                );
              })}
            </Stack>
          </Grid>
        ) : (
          <Grid item xs={6}></Grid>
        )}
      </Grid>

      <Box component="div">
        <Divider
          variant="middle"
          onClick={() => setDisplayOtherSection(!displayOtherSection)}
          sx={{ cursor: 'pointer' }}
        >
          Other <IconButton>{displayOtherSection ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
        </Divider>
      </Box>

      <Collapse in={displayOtherSection}>
        <TextField
          label={
            <>
              Sample period <HelpIconTooltip title="Period between two consecutive checks of the device." />
            </>
          }
          type="number"
          fullWidth
          disabled={locked}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">ms</InputAdornment>

                <Tooltip title="Erase this parameter">
                  <IconButton
                    size="small"
                    sx={{ marginLeft: theme.spacing(1) }}
                    onClick={() => {
                      if (frequencyInputRef.current) frequencyInputRef.current.value = '';
                      dispatch(
                        saveDeviceAction({
                          ...device,
                          properties: {
                            ...device.properties,
                            frequency: undefined,
                          },
                        })
                      );
                    }}
                  >
                    <BackspaceIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
          inputProps={{
            min: deviceMinFrequency,
            max: deviceMaxFrequency,
            step: deviceStepFrequency,
          }}
          defaultValue={device?.properties.frequency ?? frequencyDefaultValue}
          size="small"
          inputRef={frequencyInputRef}
          onBlur={(e) => {
            let value = parseInt(e.target.value, 10);
            const valid = !isNaN(value);

            if (valid) {
              if (value < deviceMinFrequency) value = deviceMinFrequency;
              if (value > deviceMaxFrequency) value = deviceMaxFrequency;
              if (value % deviceStepFrequency !== 0) {
                value = Math.round(value / deviceStepFrequency) * deviceStepFrequency;
              }
            }

            dispatch(
              saveDeviceAction({
                ...device,
                properties: {
                  ...device.properties,
                  frequency: valid ? value : undefined,
                },
              })
            );
          }}
        />
        {askNbPins && (
          <Box component="div">
            <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', my: '0.5rem' }}>
              <ToggleButtonGroup
                color="primary"
                value={ioValueMode}
                exclusive
                onChange={handleChangeIoValue}
                aria-label="Platform"
              >
                <ToggleButton value={'Bit'}>Bit</ToggleButton>

                <ToggleButton value={'Register'}>Register</ToggleButton>
              </ToggleButtonGroup>
            </FormGroup>
            <Box component="div">
              <Divider variant="middle">
                I/O Addresses
                <HelpIconTooltip
                  title={
                    <>
                      <strong>Offset input and offset output</strong>
                      <br />
                      <a
                        href="https://elearning.balyo.com/content/course/474/lesson/536/content/1817"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Associated training
                      </a>
                      <br />
                    </>
                  }
                />
              </Divider>
            </Box>
            <form id="device-input-Adresses" ref={ioAddressesFormRef}>
              <Grid container spacing={1} sx={{ mb: '0.5rem' }}>
                <Grid item xs={6}>
                  <TextField
                    name="firstIAdresses"
                    label="Offset input"
                    type="number"
                    size="small"
                    margin="dense"
                    fullWidth
                    inputProps={{
                      min: 0,
                      max: 100,
                      step: 1,
                    }}
                    defaultValue={calculateDefaultValueIoAdresses() || '0'}
                    onBlur={handleUpdateIoValues}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="secondIAdresses"
                    label="Offset output"
                    type="number"
                    size="small"
                    margin="dense"
                    fullWidth
                    inputProps={{
                      min: 0,
                      max: 100,
                      step: 1,
                    }}
                    defaultValue={calculateDefaultValueIoAdressesSecond() || '0'}
                    onBlur={handleUpdateIoValues}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        )}

        {displayKeepWritingOutputs && (
          <FormControlLabel
            control={
              <Switch
                defaultChecked={!!device.properties.keepWritingOutputs}
                onChange={handleChangeKeepWritingOutputs}
                disabled={locked}
              />
            }
            label={
              <>
                Keep Writing Outputs
                <HelpIconTooltip
                  title={
                    <>
                      Shall the output of the device be set only by the robot manager? Is the robot manager allowed to
                      overwrite an output value if it detects an incoherency.
                    </>
                  }
                />
              </>
            }
            labelPlacement="start"
            sx={{ justifyContent: 'space-around' }}
          />
        )}
      </Collapse>
    </PropertiesComponent>
  );
};

function deviceTypeToDisplayDeviceType(deviceType: DeviceType): string {
  switch (deviceType) {
    case '3BTCombox': {
      return 'Combox 3BT';
    }

    case 'IOECombox': {
      return 'Combox IOE';
    }

    case 'mobileDockingStation': {
      return 'Mobile Docking Station';
    }

    case 'smartCharger': {
      return 'Smart Charger';
    }

    case 'modbusDevice': {
      return 'Modbus Device';
    }

    case 'comboxGen2': {
      return 'Combox Gen2';
    }

    default:
      assert<Equals<typeof deviceType, never>>();
  }

  return 'Unknown device';
}
