import { Edit } from '@mui/icons-material';
import { Alert, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { selectToolAction } from 'actions';
import type { Station } from 'flows/flows';
import { setSelectedStationId } from 'flows/flows';
import { Tools } from 'models/tools';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';

export function AlertUsedEmptyStation(): JSX.Element {
  const flows = useAppSelector((state) => state.flows.flows);
  const stations = useAppSelector((state) => state.flows.stations);
  const triggers = useAppSelector((state) => state.triggers.triggers);

  const dispatch = useAppDispatch();

  const enabledTriggers = useMemo(() => {
    return triggers.filter((trigger) => trigger.enabled);
  }, [triggers]);

  const flowsWithEnableTriggers = useMemo(() => {
    return enabledTriggers
      .map((trigger) => {
        return flows.find((flow) => 'flowId' in trigger && flow.id === trigger.flowId);
      })
      .filter(isDefined);
  }, [flows, enabledTriggers]);

  const usedStations = useMemo(() => {
    const usedStationsArr: Station[] = [];

    for (let i = 0; i < flowsWithEnableTriggers.length; i++) {
      const flow = flowsWithEnableTriggers[i];

      for (let y = 0; y < flow.stations.length; y++) {
        const stationId = flow.stations[y].id;

        stations.forEach((station) => {
          if (stationId.includes(station.id)) {
            usedStationsArr.push(station);
          }
        });
      }
    }

    return [...new Set(usedStationsArr)];
  }, [flowsWithEnableTriggers, stations]);

  const emptyUsedStations = useMemo(() => {
    return usedStations.filter((station) => station.positions.length === 0);
  }, [usedStations]);

  const emptyUsedStationsUnique = useMemo(() => {
    const ids = emptyUsedStations.map((station) => station.id);
    const uniqueIds = [...new Set(ids)];

    return uniqueIds.map((id) => emptyUsedStations.find((station) => station.id === id)).filter(isDefined);
  }, [emptyUsedStations]);

  const handleClickStation = useCallback(
    (stationId: string) => {
      dispatch(setSelectedStationId(stationId));

      dispatch(
        selectToolAction({
          toolName: Tools.StationsConfiguration,
        })
      );
    },
    [dispatch]
  );

  if (emptyUsedStationsUnique.length === 0) return <></>;

  return (
    <Alert
      severity="warning"
      sx={{
        marginBottom: theme.spacing(1),
      }}
    >
      The following station{emptyUsedStationsUnique.length !== 1 ? 's are' : ' is'} used but{' '}
      {emptyUsedStationsUnique.length !== 1 ? 'have' : ' has'} no position:
      <List dense>
        {emptyUsedStationsUnique.map((station) => (
          <ListItem
            key={station.id}
            secondaryAction={
              <IconButton edge="end" size="small">
                <Edit onClick={() => handleClickStation(station.id)} />
              </IconButton>
            }
          >
            <ListItemText primary={station.name} />
          </ListItem>
        ))}
      </List>
    </Alert>
  );
}
